import React from "react";
import PhoneNumberButton from "../../elements/PhoneNumberButton";
import FormButton from "../../elements/FormButton";

export default ({ onFormClick, label }) => {
  return (
    <div className="flex flex-center flex-column visio-cta">
      <h3>Demandez votre RDV visio dans la journée :</h3>
      <div className="flex flex-align-center flex-justify-space-around w-100">
        <div className="flex-md flex-grow-1-md m-md-0 m-b-md-1 hide-big">
          <PhoneNumberButton showIcon={false} clickable={true} />
        </div>
        <div className="flex-md flex-grow-1-md m-md-0 m-b-md-1 hide-sm">
          <PhoneNumberButton showIcon={false} />
        </div>
        <div className="flex-md flex-grow-1-md">
          <FormButton onClick={onFormClick} label={label} />
        </div>
      </div>
    </div>
  );
};
