import React, { useContext } from "react";
import { AppContext } from "../../context";

export default ({ showIcon = true, clickable }) => {
  const [state] = useContext(AppContext);
  const { phoneNumber } = state;

  return (
    <div className="flex-md flex-grow-1-md">
      {clickable ? (
        <a
          href={"tel:" + phoneNumber.internationalFormat}
          className="flex-md flex-grow-1-md no-text-decoration"
        >
          <div className="phone-number-button bg-green white flex-md flex-grow-1-md">
            {showIcon ? (
              <img
                src="/img/call-answer_30x.png"
                alt="Plombier à Toulouse"
                width="22px"
              />
            ) : null}
            {phoneNumber.default}
          </div>
        </a>
      ) : (
        <div className="phone-number-button bg-green white">
          {showIcon ? (
            <img
              src="/img/call-answer_30x.png"
              alt="Plombier à Toulouse"
              width="22px"
            />
          ) : null}
          {phoneNumber.default}
        </div>
      )}
    </div>
  );
};
