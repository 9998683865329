import React, { Component } from "react";

import SEO from "../../elements/SEO";

import NotificationHelper from "../../../utils/NotificationHelper";
import ReferrerHelper from "../../../utils/ReferrerHelper";
import GAEventHelper from "../../../utils/GAEventHelper";
import ContactFormModal from "../../elements/ContactFormModal";
import CONSTANTS from "../../../utils/constants";

import VisioCTA from "./VisioCTA";

class App extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      phoneNumber: { default: "" },
      currentLabel: null,
    };
  }
  componentDidMount() {
    const phoneNumber = new ReferrerHelper(document).getPhoneNumber();
    this.setState({ phoneNumber });
  }

  handleFormClick = (event, metadata = {}) => {
    event.preventDefault();
    let { label } = metadata;
    if (!label && event && event.currentTarget) {
      label = event.currentTarget.dataset.label;
    }
    const body = {
      type: "demande-formulaire",
      raw: {},
      pageReference: CONSTANTS.PAGE_REFERENCES.PLOMBIER_VISIO,
    };
    GAEventHelper.triggerClickEvent({
      category: CONSTANTS.GOOGLE_ANALYTICS.CATEGORIES.DEMANDE_DEVIS,
      action: CONSTANTS.GOOGLE_ANALYTICS.ACTIONS.OPEN,
      label: label,
      options: {
        window,
      },
    });
    this.setState({
      showModal: true,
      currentLabel: label,
    });
    NotificationHelper.sendNotification(body);
  };

  handleCloseContactFormModal = () => {
    this.setState({
      showModal: false,
      currentLabel: null,
    });
  };

  render() {
    return (
      <main className="main-section-online ">
        {this.state.showModal ? (
          <ContactFormModal
            onClose={this.handleCloseContactFormModal}
            label={this.state.currentLabel}
            reference={CONSTANTS.PAGE_REFERENCES.PLOMBIER_VISIO}
          />
        ) : null}
        <section className="online-section section-intro">
          <div className="first-element flex flex-align-center flex-justify-space-evenly m-b-2">
            <div className="section-title flex-50 flex-md-order-1 flex-md-100">
              <div className="headline">Mon plombier en visio</div>
              <h1>
                Un souci de plomberie ou de chauffage en période de{" "}
                <span className="green">#confinement</span> ?
              </h1>
              <p className="">
                Selfcity mobilise son équipe de techniciens
                <br className="hide-sm" /> pour vous assister à distance.{" "}
                <span className="green bold">Gratuitement</span>.
              </p>
            </div>
            <div>
              <img
                className="section-img hide-sm"
                src="/img/icons/visio-img_v2.png"
                alt="Plombier en visioconférence"
                width="180px"
              />
              <img
                className="section-img hide-big"
                src="/img/icons/visio-img_sm_v2.png"
                alt="Plombier en visioconférence"
                width="80px"
              />
            </div>
          </div>
          <div className="flex flex-center">
            <VisioCTA onFormClick={this.handleFormClick} label="intro" />
          </div>
        </section>
        <section className="online-section section-2 light-grey-bg">
          <h2 className="center section-title">
            Les déplacements, ce n'est pas automatique !
          </h2>
          <div className="cards flex flex-justify-space-evenly">
            <div className="card flex-20 flex-md-100">
              <div className="center">
                <img
                  src="/img/icons/icon-visio.png"
                  alt="Visio-conférence"
                  width="100"
                />
              </div>
              <h3 className="center">Visio-conférence</h3>
              <div>
                Échangez en visio-conférence depuis votre smartphone sans
                installer d’application.
              </div>
            </div>
            <div className="card flex-20 flex-md-100">
              <div className="center">
                <img
                  src="/img/icons/icon-diploma_v2.png"
                  alt="Plombier diplomé"
                  width="150"
                />
              </div>
              <h3 className="center">Plombiers diplômés</h3>
              <div>
                Nos intervenants sont des plombiers-chauffagistes diplômés et
                expérimentés.
              </div>
            </div>
            <div className="card flex-20 flex-md-100">
              <div className="center">
                <img src="/img/icons/icon-0E.png" alt="Gratuit" width="100" />
              </div>
              <h3 className="center">Gratuité complète</h3>
              <div>
                Pour faire face au confinement, Selfcity propose ce service
                gratuitement pour tous.
              </div>
            </div>
          </div>

          <div className="flex flex-center">
            <VisioCTA onFormClick={this.handleFormClick} label="section-2" />
          </div>
        </section>

        <section className="online-section section-3">
          <h3 className="center section-title">
            Souvent, quelques{" "}
            <span className="green bold">astuces simples </span>
            suffisent
            <br className="hide-sm" /> à{" "}
            <span className="green bold">résoudre </span>
            le problème, ou à le <span className="green bold">freiner </span>
            en attendant <br className="hide-sm" /> l’intervention d’un
            professionnel.
          </h3>
          <div className="center section-img">
            <img
              src="/img/icons/icon-pipelines.png"
              alt="Tuyau et chaudière"
              width="160px"
            />
          </div>

          <div className="flex flex-center">
            <VisioCTA onFormClick={this.handleFormClick} label="section-3" />
          </div>
        </section>

        <section className="online-section section-how light-grey-bg">
          <div className="center ">
            <h3 className="section-title">Comment ça fonctionne ?</h3>
          </div>
          <div className="first-element flex flex-align-center flex-justify-space-evenly m-b-2">
            <div className="flex flex-column flex-center">
              <img
                className="section-img img-lamp"
                src="/img/icons/icon-lamp.png"
                alt="Idée de plombier"
                width="70px"
              />
              <img
                className="section-img img-plumber"
                src="/img/icons/visio-img_sm_v2.png"
                alt="Plombier en visioconférence"
                width="140px"
              />
            </div>
            <div className="section-content flex-50 flex-md-order-1 flex-md-100">
              <p className="list-element">
                <span className="element-number">1</span>
                <span className="element-point">.</span> Remplissez le
                formulaire ou appelez-nous pour nous décrire le problème et vos
                disponibilités.
              </p>
              <p className="list-element">
                <span className="element-number">2</span>
                <span className="element-point">.</span> Nous vous proposons un
                créneau qui vous convient avec un technicien compétent.
              </p>
              <p className="list-element">
                <span className="element-number">3</span>
                <span className="element-point">.</span> Vous recevez un lien
                pour la visioconférence. Tout est sur le web, aucune application
                à installer.
              </p>
              <p className="list-element">
                <span className="element-number">4</span>
                <span className="element-point">.</span> Après assistance, soit
                le problème est corrigé, soit une solution temporaire est
                appliquée en attendant une intervention physique.
              </p>
              <p className="list-element">
                <span className="element-number">5</span>
                <span className="element-point">.</span> Nous vous souhaitons
                une bonne journée :) et vous ne payez rien.
              </p>
            </div>
          </div>
          <div className="flex flex-center">
            <VisioCTA onFormClick={this.handleFormClick} label="how" />
          </div>
        </section>

        <section className="online-section section-who ">
          <h3 className="center section-title m-b-3">Qui sommes-nous ?</h3>
          <div className="flex flex-justify-center">
            <div className="m-r-2 m-md-0 flex-md-100">
              <img
                className="team-img"
                src="/img/team2_768x.jpg"
                alt="Equipe Selfcity"
                width="400px"
              />
            </div>
            <div className="flex-30 flex-md-100">
              <p>
                Selfcity est une entreprise spécialisée dans les dépannages et
                réparations en plomberie et chauffage.
              </p>
              <p>
                Nous sommes basés à Toulouse au :
                <br />2 avenue de Lombez, 31300
                <br />
                Près du métro Patte d’Oie
              </p>
              <p>
                Nous contacter : <br />
                {this.state.phoneNumber.default} <br />
                contact@selfcity.fr
              </p>
            </div>
          </div>
        </section>

        <section className="online-section last-section">
          <div className="flex flex-center">
            <VisioCTA onFormClick={this.handleFormClick} label="last-section" />
          </div>
          <p className="center last-text m-t-3">
            Un problème urgent nécessitant
            <br /> le déplacement d’un professionnel ?{" "}
            <a href="/">Cliquez ici.</a>
          </p>
        </section>
      </main>
    );
  }
}

export default App;
