import React from "react";

import Layout from "../layouts/Layout";

import CONSTANTS from "../utils/constants";
import Visio from "../components/pages/plombier-visio";

export default () => {
  const thumbnail = "/img/icons/visio-img_v2.png";

  return (
    <Layout
      reference={CONSTANTS.PAGE_REFERENCES.PLOMBIER_VISIO}
      showSMMenuLink={false}
      metadata={{
        title: "Selfcity | Mon plombier en visio",
        description:
          "Dépannage de plomberie et chauffage en visioconférence. Gratuit pendant le confinement.",
        thumbnail,
      }}
    >
      <Visio />
    </Layout>
  );
};
